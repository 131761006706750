::-moz-selection {
    color: $white;
    background: rgba(var(--accent), 1);
}

::selection {
    color: $white;
    background: rgba(var(--accent), 1);
}

._fadein {
    opacity: 0;
}

._no_image {
    background: rgba(var(--dark), 1);
}

._offset_top {
    transform: translateY(100%);
}

._logodot {
    transition: 600ms ease-out;
}

._dot {
    &s {
        &:hover {
            ._dot {
                &:after {
                    color: currentColor;
                }
            }       
        }
    }

    @include breakpoint(sm-down) {
        br {
            display: none;
        }
    }

    &:after {
        content: '.';
        color: rgba(var(--accent), 1);
    }
}

.list-heading {
    display: flex;
    margin-bottom: 1rem;
    color: rgba(var(--dark), 0.64);
}

.devmode {
    position: fixed;
    padding: 1rem 1.5rem;
    color: rgba(var(--dark), 0.64)
}


@keyframes preloadAnimation{
    0%{
        background-position: -50vw 0
    }
    100%{
        background-position: 50vw 0
    }
}

._preload {
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: preloadAnimation;
    animation-timing-function: linear;
    background: rgba(var(--white), 1);
    background-size: 100vw 100%;
    position: relative;
    animation-duration: 2s;
    background: linear-gradient(to right, rgba(var(--dark), 0.12) 8%, rgba(var(--white), 1) 38%, rgba(var(--dark), 0.12) 54%);
    pointer-events: none;
}

.responsive-object {
    width: 100%;
    height: auto;
    margin: 1.75rem 0 0.75rem;
    background: rgba(var(--dark), 0.24);
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

hr {
    all: unset;
    width: 100%;
    margin: 1.5rem 0;
    border-top: 0.125rem dashed rgba(var(--dark), 0.24);
}