:root {
    font-size: 16px;
}

// Base
@import 'reset';
@import 'variables';
@import 'breakpoints';
@import 'typography';
@import 'cols';

// Packages
@import '../../node_modules/swiper/swiper';

// Ui
@import 'ui/buttons';
@import 'ui/elements';
@import 'ui/forms';
@import 'ui/search';
@import 'ui/sections';
@import 'ui/logo';
@import 'ui/placeholder';

// Components
@import 'components/header';
@import 'components/menu';
@import 'components/hero';
@import 'components/quote';
@import 'components/stats';
@import 'components/footer';
@import 'components/team';
@import 'components/list';
@import 'components/album';
@import 'components/process';
@import 'components/blocks';
@import 'components/stakeholders';
@import 'components/methods-slider';
@import 'components/methods';
@import 'components/usps';
@import 'components/highlight';
@import 'components/call-to-action';
@import 'components/trois';
@import 'components/lang-selector';
@import 'components/socials';
@import 'components/gallery';
@import 'components/share-box';
@import 'components/posts';
@import 'components/popup';
@import 'components/simple';
@import 'components/timeline';

.colortets {
    display: flex;
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    box-shadow: inset 0 0 0 0.25rem #fff, 0 0.5rem 1rem rgba(0,0,0,0.2);
    background: currentColor;
    cursor: pointer;
}