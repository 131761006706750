.search {
	flex-grow: 2;
	position: relative;

	&-field {
		width: 100%;
		height: 3rem;
		margin: 0.125rem 0;
		position: relative;
		cursor: text;

		.icon {
			margin: 0.75rem;
			color: currentColor;
			position: relative;
			pointer-events: none;
			z-index: 2;

			&.close {
				pointer-events: all;
				cursor: pointer;
				opacity: 0.4;
				transition: $t-fast;

				&.hidden {
					opacity: 0;
					pointer-events: none;
				}

				&:hover {
					opacity: 1;
				}
			}
		}

		input {
			all: unset;
			width: 100%;
			height: 100%;
			padding: 0 0.5rem 0 3rem;

			background: rgba(var(--dark), 0.08);
			box-shadow: inset 0 0 0 rgba(var(--dark), 0), 0 0 0 .125rem rgba(var(--green), 0), 0 0 0 rgba(var(--green), 0);
			box-sizing: border-box;
			border-radius: 0.125rem;

			position: absolute;
			z-index: 1;
			transition: $t-button;

			&:hover {
				background: rgba(var(--dark), 0.12);
				box-shadow: inset 0 0 0 .0625rem rgba(var(--dark), 0.12), 0 0 0 .125rem rgba(var(--green), 0), 0 0 0 rgba(var(--green), 0);
			}

			&:focus {
				background: rgba(var(--white), 1);
				box-shadow: inset 0 0 0 .0625rem rgba(var(--dark), 0), 0 0 0 .125rem rgba(var(--green), 1), 0 .125rem .25rem rgba(var(--green), 0.08);
			}
		}
	}

	.results {
		width: 100%;
		padding: 0.125rem;

		background: rgba(var(--white), 1);
		box-sizing: border-box;
		border-radius: 0.25rem;

		position: absolute;

		&-section {

		}
	}
}