.block {
	position: relative;
	// background: rgba(var(--white), 1);
	// z-index: 1;

	&-404 {
		.button-wrap {
			margin-top: 1.5rem;
		}
	}

	&-team {
		._dot::after {
			color: rgba(var(--blue), 1);
		}

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			background: rgba(var(--blue), 1);
			opacity: 0.1;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		._intro_p {
			margin-bottom: 3rem;
		}
	}

	&-content_section {
		@include breakpoint(sm-down) {
			._dot {
				margin-bottom: 1rem;
			}

			.button {
				margin-bottom: 1rem;				
			}
		}
	}

	&-trois_image {
		canvas {
			touch-action: auto !important;
		}
	}
}


.sub-block {
	&-buttons {
		margin: 1.5rem 0 0 -0.5rem;
	}
}