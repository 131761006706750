.quote {
	position: relative;

	&-wrapper {
		background: rgba(var(--white), 1);

		&.has-image {
			margin: 1rem 0;

			@include breakpoint(sm) {
				margin: 2.5rem 0;
			}
		}

		&.dark {
			--accent: 255, 255, 255;

			figure {
				opacity: 0.32;
			}
		}
		
	    figure {
	    	width: 100%;
	    	opacity: 0.12;

			&.bg {
				width: 100%;
		    	height: 100%;
				pointer-events: none;
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    	z-index: 0;

		    	img {
					width: 100%;
					height: 100%;
					object-fit: cover;
		    	}
			}
	    }
	}

	&-container {
		&:before {
			content: '"';
			width: 100%;
			height: 5rem;
			color: rgba(var(--accent), 1);
			font-family: $font-heading;
			font-size: 12rem;
			line-height: 12rem;
			text-align: center;
		}

		* {
			text-align: center;
		}

		.author {
			margin-top: 0.75rem;
			color: rgba(var(--accent), 1);
		}
	}

	& > * {
		color: rgba(var(--white), 1);

		&:last-child {
			margin: 0 !important;
		}
	}

	&.accent {
		background: rgba(var(--accent), 1);

		* {
			color: rgba(var(--white), 1);
		}
	}

	&-text {
	    padding-top: 2rem;
	    padding-bottom: 2rem;

	    @include breakpoint (sm) {
	        padding-top: 5rem;
	        padding-bottom: 5rem;
	    }

		&.center {
			text-align: center;
		}

		p {
			// font-family: $font-serif;
			font-size: 1.375rem;
			color: inherit;
		}

		.button {
			margin-top: 1.75rem;

			* {
				color: inherit;
			}
		}
	}
}