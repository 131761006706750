.call-to-action {
	background: rgba(var(--accent), 1);

	figure {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		img {
			width: 100%;
			height: 100%;
			mix-blend-mode: multiply;
			filter: grayscale(100%);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.12;
			object-fit: cover;
		}
	}

	.content {
		text-align: center;
		margin-bottom: 1.5rem;

		p {
			font-size: 1.375rem
		}
	}

	._dot {
		&:after {
            color: rgba(var(--dark), 1);
        }
	}
}