section.block {
	&-team {
		overflow: hidden;
	}

	&-header,
	&-methods,
	&-call_to_action,
	&-team {
		& + section {
			&.block-content_section,
			&.block-usps,
			&.block-page_content,
			&.block-content,
			&.block-posts,
			&.block-form,
			&.block-simple {
			    padding-top: $bp; @include breakpoint (sm-down) { padding-top: $sp; }
			}
		}
	}

	&-content,
	&-content_section,
	&-page_content,
	&-posts,
	&-simple {
		& + .block-posts,
		& + .block-methods,
		& + .block-call_to_action,
		& + .block-usps,
		& + .block-simple,
		& + .block-trois_image,
		& + .block-team,
		& + footer {
			margin-top: $bp; @include breakpoint (sm-down) { margin-top: $sp; }
		}
	}

	&-simple {
		& + .block-trois_image {
			margin-top: $bp; @include breakpoint (sm-down) { margin-top: $sp; }
		}
	}
}