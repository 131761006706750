.highlight {
	&-fixture {
		padding: 1.5rem 2rem 1.375rem;
		background: rgba(var(--white), 1);
		box-shadow: 0 0.5rem 1rem rgba(var(--dark), 0.16);
		border-radius: 1rem;
		opacity: 0;
		color: rgba(var(--accent), 1);

		position: absolute;
		right: 2.5rem;
		top: calc(100% - 5rem);
		transform: translateY(2rem);
		z-index: 100;

		&:hover {
			background: rgba(var(--accent), 1);
			color: rgba(var(--white), 1);

			p {
				color: inherit;
			}
		}
	}

	.title {
		margin-bottom: 0.5rem;
		font-weight: 600;

		i {
			margin-right: 0.75rem;
		}
	}

	p {
		margin-bottom: 0;
	}
}