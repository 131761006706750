.posts {
}

.block {
	&-posts {
		@include breakpoint(sm-down) {
			
		}
		
		article {
			border-radius: 1.5rem;
			background: rgba(var(--dark), 0);
			transition: $button;

			@include breakpoint(sm-down) {
				padding: 0.5rem 1rem 0.5rem 0.5rem;
			}

			@include breakpoint(sm) {
				padding: 0.75rem 1.5rem 0.75rem 0.75rem;
			}

			&:hover {
				background: rgba(var(--dark), 0.12);
			}

			a {
				width: 100%;

				h1 {
					@extend h4;
					margin-bottom: 0;

					@include breakpoint(sm) {
						margin-bottom: 0.75rem
					}
				}

				.date {
					display: none;
				}

				figure {
					flex-shrink: 0;
					width: 10rem;
					height: 7rem;
					margin-right: 1rem;
					border-radius: 1rem;
					overflow: hidden;

					@include breakpoint(sm) {
						width: 14rem;
						height: 10rem;
						margin-right: 2rem;
						flex-shrink: 0;
					}

					img, picture {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}

				.content {
					height: 100%;
					overflow: hidden;
					padding: 0.5rem 0;
				}

				p {
					margin: 0;
					text-overflow: ellipsis;
				}
			}
		}
	}
}