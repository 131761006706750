.placeholder {
    &-method-slider {
        min-height: 51.125rem;
    }

    &-team {
        min-height: 27.5rem;
    }

    &-maped {
        min-height: 64rem;

        @include breakpoint(sm-down) {
            min-height: 28rem;
        }
    }
}