footer {
	background: rgba(var(--white), 1);
	color: rgba(var(--dark), 1);

	@include breakpoint (sm-down) {
		padding: 5rem 0 2.5rem;

		.col {
			margin-bottom: 2.5rem;
		}

		.canada {
			width: auto;

			svg {
				height: 3.5rem;
			}
		}
	}

	&.stick {
		position: sticky;
		bottom: 0;
		z-index: 0;
	}

	p {
		margin-bottom: 4rem;
		font-size: 1.25rem;
		color: rgba(var(--dark), 1);

		@include breakpoint (sm-down) {
			margin-bottom: 1.5rem;
		}
	}

	a {
		color: inherit;

		&.logo {
			display: flex;

			@include breakpoint(sm) {
				position: absolute;
				top: -1.75rem;
			}
		}
	}

	ul {
		transition: $t-button;

		&:hover {
			color: rgba(var(--dark), 0.48);

			a {
				&:hover {
					color: rgba(var(--dark), 1);
				}
			}
		}

		&.menu {
			margin: 0 -0.25rem;

			li {
				display: none;

				&.level {
					&-3 {
						display: flex;
					}
				}

				&.active {
				}

				a {
					display: inline-flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					align-items: baseline;
					padding: 0.25rem;

					svg {
						display: inline-flex;
						margin-right: 0.5rem;
					}

					span {
						font-size: 2rem;
						font-family: $font-primary;
						color: inherit;
					}
				}
			}
		}

		&.socials {
			svg {
				width: 2.5rem;
			}
		}

		&.legal {
			margin-left: -0.5rem;

			a {
				padding: 0.5rem;
			}
		}
	}

	.bottom {
		margin-top: 8rem;
		color: rgba(var(--dark), 0.4);

		@include breakpoint (sm-down) {
			margin-top: 4rem;

			.col {
				margin: 0;
			}
		}

		a {
			margin-top: -1rem;
			margin-left: -1rem;
			padding: 1rem;
			transition: $button;
			position: relative;

			&:after {
				content: '';
				width: calc(100% - 2rem);
				height: 0.125rem;
				background: currentColor;
				opacity: 0.12;
				position: absolute;
				left: 1rem;
				bottom: 0.25rem;
			}

			&:hover {
				color: rgba(var(--dark), 1);

				&:after {
					opacity: 0.4;
				}
			}
		}
	}
}