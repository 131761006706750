.stats {
    padding-top: 0;
    padding-bottom: 5rem;

    article {
        padding-right: 4rem;
        box-sizing: border-box;

        @include breakpoint (sm-down) {
            padding-right: 2.5rem;
            margin-bottom: 1.5rem;

            &:last-child {
                margin: 0;
            }
        }

        h3 {
            margin-top: 0.25rem;
        }

        svg.img {
            margin-right: 2.5rem;
            flex-shrink: 0;

            @include breakpoint (sm-down) {
                margin-right: 1.5rem;
            }
        }

        .button {
            margin-top: 1rem;
        }
    }
}