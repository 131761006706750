.stakeholder {
	&-wrapper {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding-top: 2rem;
		padding-bottom: 4rem;

		@include breakpoint(sm) {
			padding-top: 4rem;
			padding-bottom: 8rem;
		}
	}

	&-content {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		box-sizing: border-box;

		@include breakpoint(sm) {
			width: calc(100% / 3 * 2 + 2rem);
			margin: -0.5rem;
		}

		@include breakpoint(md) {
			width: calc(100% / 3 * 2 + 2rem);
			margin: -1rem;
		}

		.item {
			display: flex;
			max-width: 50%;
			padding: 0.5rem;
			box-sizing: border-box;
			color: rgba(var(--dark), 1);

			@include breakpoint(sm-down) {
				width: 100%;
				max-width: 100%;
				padding: 0 0 1rem;
			}

			@include breakpoint(md) {
				padding: 1rem;
			}

			.stakeholder {
				padding: 2.5rem 2rem 1rem;
				border-radius: 2rem;
				box-sizing: border-box;
				box-shadow: inset 0 0 0 0.0625rem rgba(var(--dark), 0.2);

				h1 {
				    margin-bottom: 1.5rem;
				    font-size: 2rem;
				    // font-weight: 600;
				    line-height: 116%;

				    @include breakpoint(sm-down) {
				        margin-bottom: 1rem;
				        font-size: 1.75rem
				    }
				}

				@include breakpoint(sm-down) {
					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					padding: 1rem 1.5rem 0.5rem;
					border-radius: 1rem;

					h1._dot {
						margin: 0.375rem 0 1rem;
					}
				}

				.icon {
					margin: 0 0 3rem;

					@include breakpoint(sm-down) {
						width: 2rem;
						height: auto;
						margin: 0.5rem 1.25rem 0 0;
					}
				}
			}

			&:hover {
				.stakeholder {
					background: rgba(var(--accent), 1);
					box-shadow: inset 0 0 0 0.125rem rgba(var(--dark), 0);
					color: rgba(var(--white), 1);

					p {
						color: rgba(var(--white), 1);
					}

					.icon {
						color: rgba(var(--white), 1);
					}

					.button * {
						color: rgba(var(--white), 1);
					}
				}
			}
		}
	}
}

.stakeholder {
	&-slider {
		@include breakpoint(sm-down) {
			display: none;
		}

		@include breakpoint(sm) {
			width: calc(100% / 3 - 32px);
			position: relative;
		}

		canvas {
			width: 100%;
			height: 100% !important;
			border-radius: 2rem;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}