.methods {
	&-bar {
		width: 100%;
		background: #E7E8E9ee;
		backdrop-filter: blur(1rem);
		position: sticky;
		top: 0;
		z-index: 2;
	}

	&-wrapper {
		padding: 2.5rem 0;
	}

	&-section {
		&.odd {
			.content {
				@include breakpoint(sm) {
					padding-left: 1rem;
				}
			}
		}

		&.even {
			flex-direction: row-reverse;

			.content {
				@include breakpoint(sm) {
					padding-right: 1rem;
				}
			}
		}

		._logodot {
			color: rgba(var(--accent), 1);
		}

		figure {
			height: 100%;
			// max-height: 30rem;
			position: relative;
			background: rgba(var(--accent), 1);
			border-radius: 1rem;

			@include breakpoint(sm) {				
				border-radius: 2rem;
			}

			img, picture {
				mix-blend-mode: multiply;
				width: 100%;
				height: 100%;
				object-fit: cover;

				@include breakpoint(sm) {				
					position: absolute;
				}

				@include breakpoint(sm-down) {
					height: 16rem;
				}
			}

			.logo {
				height: 100%;
				flex-shrink: 1;

				&-wrap {
					height: 4rem;
					position: absolute;

					.method-indicator {
			            display: flex;
			            margin-left: 1rem;
			            box-sizing: border-box;
			            border-radius: 0.25rem;
			            line-height: 2rem;
			            font-size: 2rem;
			            font-weight: 700;
			            background: rgba(var(--white), 1);
			            cursor: pointer;

			            ._code {
			                padding: 0.125rem 0.375rem 0.25rem;
			                display: inline;
			                white-space: nowrap;
			                color: rgba(var(--accent), 1);
			            }
					}
				}
			}

			svg {
				color: rgba(var(--white), 1);
			}
		}

		.content {
			padding: 2.5rem;
			box-sizing: border-box;

			@include breakpoint(sm-down) {
				padding: 2.5rem 0 0 0 ;
			}

			.logo {
				height: 100%;
				flex-shrink: 1;

				&-wrap {
					height: 4rem;
					margin-bottom: 1.5rem;

					@include breakpoint(sm-down) {
						display: none;
					}

					.method-indicator {
			            display: flex;
			            margin-left: 1rem;
			            box-sizing: border-box;
			            border-radius: 0.25rem;
			            line-height: 2rem;
			            font-size: 2rem;
			            font-weight: 700;
			            background: rgba(var(--accent), 1);
			            cursor: pointer;

			            ._code {
			                padding: 0.125rem 0.375rem 0.25rem;
			                display: inline;
			                white-space: nowrap;
			                color: rgba(var(--white), 1);
			            }
					}
				}
			}
		}
	}
}