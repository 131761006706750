.header {
	&-wrapper {
	    position: relative;
	    z-index: 10;

	    .inner {
			padding: 2.5rem 0;

			@include breakpoint(sm) {
				padding: 5rem 0;
			}
		}

	    figure {
	    	width: 100%;

			&.image {
		    	height: 10rem;

				@include breakpoint(sm-down) {
					width: 100vw;
					margin: 0 -1rem;
				}

				img {
					width: 100%;
					height: auto;
					border-radius: 2rem;
					object-fit: cover;

					@include breakpoint(sm-down) {
						border-radius: 1rem 1rem 0 0;
						height: 10rem;
					}
				}

				video {
					width: 100%;
					height: auto;
					border-radius: 2rem;
					background: black;
					// position: absolute;
					// top: 0;
					// left: 0;
					// right: 0;

					@include breakpoint(sm-down) {
						border-radius: 1rem 1rem 0 0;
					}
				}
			}

			&.placeholder {
				@extend ._preload;
				width: 100%;
				height: 10rem;
				// animation-duration: 1s;
				border-radius: 2rem 2rem 0 0 ;

				@include breakpoint(sm-down) {
					width: 100vw;
					border-radius: 1rem 1rem 0 0;
				}
			}

			&.bg {
				pointer-events: none;
				width: 100%;
		    	height: 100%;
		    	position: absolute;
				// background: currentColor;
		    	top: 0;
		    	left: 0;
		    	right: 0;
		    	z-index: 0;
		    	// filter: blur(1rem);

		    	img {
					width: 100%;
					height: 100%;
		    		// opacity: 0.24;
					object-fit: cover;
		    	}
			}
	    }
	}

	&-container {
		padding: 8rem 0 0;
		background: rgba(var(--white), 1);
		color: rgba(var(--dark), 1);
		position: relative;

		@include breakpoint(sm) {		
			padding: 12rem 0 0;
		}

		&.accent {
			background: rgba(var(--accent), 1);
			color: rgba(var(--white), 1) !important;

			._logodot,
			._dot::after {
				color: rgba(var(--white), 1);
			}

			.button {
				&.primary {
					&:hover {
						span {
							color: rgba(var(--accent), 1) !important;
						}
					}
				}
			}
		}

		.post-wrap {
			.byline {
				padding-bottom: 2.5rem;
			}

			.tags {
				@include breakpoint(sm) {
					margin-left: 1rem;
				}

				span {
					margin-left: 0.5rem;
					padding: 0.5rem;
					border-radius: 0.25rem;
					box-shadow: inset 0 0 0 0.0625rem rgba(var(--dark), 0.12);
				}
			}
		}
	}
}