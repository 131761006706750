.socials {
	margin: 0 -0.625rem;

	&:hover {
		a {
			opacity: 0.4;

			&:hover {
				opacity: 1;
			}
		}
	}

	a {
		display: flex;
		padding: 0.625rem;
		transition: $button;

		svg {
			min-width: 2rem;
			height: auto;
			color: rgba(var(--dark), 1);
		}
	}
}