.menu {
	&-actions {
		width: 100%;
		position: absolute;
	}

	&-fixture {
		pointer-events: all;
		position: relative;
	}

	&-top {
		width: 100%;
		margin-bottom: 4rem;
	}

	&-side {
		width: 40rem;
		height: 100vh;
		padding: 4rem 4.5rem 0;
		box-sizing: border-box;
		pointer-events: all;

		position: fixed;
		top: 0;
		right: 0;
		z-index: 9999;

		overflow: auto;

		transform: translateX(40rem);
		background: rgba(var(--white), 1);
		color: rgba(var(--dark), 1);

		@include breakpoint (sm-down) {
			max-width: 100%;	
			padding: 2.5rem 2rem
		}

		._logodot {
			color: rgba(var(--accent), 1) !important;
		}

		ul {
			margin-bottom: 10vh;

			&:hover {
				a {
					opacity: 0.4;

					&:hover {
						opacity: 1;
					}
				}
			}

			li {
				a {
					opacity: 1;
					transition: $button;

					span {
						font-size: 2.5rem;
					}

					svg {
						display: inline-flex;
						margin-right: 0.5rem;
					}

					&.active {
						span {
							// color: white
						}
					}
				}
			}
		}
	}

	&-wrapper {
		width: 100%;
		height: auto;
		padding: 2.5rem 0 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		z-index: 999;
		color: inherit;

		@include breakpoint(sm) {
			padding: 4rem 0 0;
		}

		._logodot {
			color: rgba(var(--accent), 1);
		}

		a {
			pointer-events: all;
			color: inherit;
		}

		&.open {
			pointer-events: all;

			.overlay {
				opacity: 1;
			}

			.menu {
				&-container {
					transform: translateX(0);
				}
			}
		}
	}

	&-toggle {
		margin: 0 -2rem 0 0;
		padding: 2rem;
		box-sizing: border-box;
		cursor: pointer;

		@include breakpoint(sm-down) {
			margin: 0 -0.5rem 0 0;
			padding: 2rem 0.5rem 2rem 2rem;
		}

		&:hover {
			span {
				opacity: 1;
			}
		}

		span {
			padding: 0 1.5rem;
			opacity: 0.64;
			transition: $button;
		}
	}

	&-container {
		$max: 48rem;
		max-width: 100%;
		width: $max;
		height: 100%;
		padding-top: 3rem;
		padding-bottom: 8rem;
		padding-left: 2rem;

		background: rgba(var(--accent), 1);
		box-sizing: border-box;
		box-shadow: 4rem 0 0 rgba(var(--accent), 1);
		overflow-y: auto;
		position: absolute;
		right: 0;

		transition: transform $t-menu;
		transform: translateX($max);

        -webkit-clip-path: polygon(0% 0%, 120% 0%, 120% 100%, 3rem 100%, 0% calc(100% - 3rem));
        -moz-clip-path: polygon(0% 0%, 120% 0%, 120% 100%, 3rem 100%, 0% calc(100% - 3rem));
        clip-path: polygon(0% 0%, 120% 0%, 120% 100%, 3rem 100%, 0% calc(100% - 3rem));

        @include breakpoint (sm) {
			padding-top: 4.5rem;
        }

        @include breakpoint (md) {
        	padding-left: 8rem;
        }

        ul {
        	width: 100%;

			&:hover {
				color: rgba(var(--white), 0.48);

				a {
					&:hover {
						color: rgba(var(--white), 1);
					}
				}
			}

			a {
    			color: inherit;
			}

        	&.menu {
        		margin-bottom: 4rem;
        		font-size: 2rem;

        		@include breakpoint(sm) {
	        		font-size: 2.5rem;
        		}

    			span {
    				font-family: $font-heading;
    			}
        	}

			&.social {
        		margin-bottom: 4rem;

				svg {
					width: 2.5rem;
					height: auto;
				}
			}
        }
	}
}