.trois {
	overflow: hidden;
	min-height: 28rem !important;

	@include breakpoint(sm) {
		min-height: 48rem !important;
	}

	@include breakpoint(md) {
		min-height: 64rem !important;
	}

	.fixture {
		width: 100%;
		height: 100%;
		position: absolute;

		// .lalal {
		// 	position: absolute;
		// 	pointer-events: none;
		// }

		// .indicator {
        //     display: flex;
        //     margin-left: 1rem;
        //     box-sizing: border-box;
        //     border-radius: 0.25rem;
        //     line-height: 2rem;
        //     font-size: 2rem;
        //     font-weight: 700;
        //     background: rgba(var(--white), 1);
        //     cursor: pointer;
		// 	position: absolute;

        //     ._code {
        //         padding: 0.125rem 0.375rem 0.25rem;
        //         display: inline;
        //         white-space: nowrap;
        //         color: rgba(var(--accent), 1);
        //     }
		// }

		.highlight {
			&-fixture {
				position: absolute;
				
			}
		}
	}
}