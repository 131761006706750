.input-item {
    all: unset;
    appearance: none;
    width: 100%;
    max-width: 100%;
    min-height: 3.5rem;
    padding: 1rem;
    border-radius: 0.25rem;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.0625rem rgba(var(--dark), 0.12);

    line-height: 100%;
    font-size: 1.125rem;
    color: rgba(var(--dark), 1);

    &:hover {
        box-shadow: inset 0 0 0 0.0625rem rgba(var(--accent), 0.64), 0 0 0 rgba(var(--accent), 0.04);
    }

    &:focus {
        box-shadow: inset 0 0 0 0.125rem rgba(var(--accent), 1), 0 0.125rem 0.25rem rgba(var(--accent), 0.12);
        background: rgba(var(--dark), 0);

        & ~ span.label {
            color: rgba(var(--accent), 1);
        }
    }
}

input,
textarea,
select {
    @extend .input-item;
}

label { // input item
    display: flex;
    position: relative;
    cursor: text;
    padding-top: 1rem;

    span.label {
        padding: 0.5rem;
        box-shadow: inset 0 1.25rem 0 0 rgba(var(--white), 1);
        color: rgba(var(--dark), 0.64);
        position: absolute;
        top: 0;
        left: 0.5rem;
    }
}

form { // Form template
    ul { // django fixture
        margin: 0 -0.25rem;

        li {
            padding: 0.25rem;
        }
    }

    .submit-form {
        margin-top: 1.5rem;
    }
}