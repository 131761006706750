.team {
	&-top {
		padding-bottom: 4rem;
	}

	&-slider {
		width: 100%;

		.swiper {
	        overflow: visible !important;

			&-wrapper {
				-webkit-transition-timing-function:linear!important; 
				-o-transition-timing-function:linear!important;
				transition-timing-function:linear!important; 
			}

			&-slide {
				width: auto;
				max-width: 21.5rem;
				height: 100%;

				&:hover {
					.member {
						background: rgba(var(--blue), 1);
						box-shadow: 0 1rem 2rem rgba(var(--blue), 0.24);
						transform: translateY(-0.25rem);

						figure {
							// padding: 1rem 0.5rem 0;
						}

						.inner {
							background: linear-gradient(to top, rgba(var(--blue), 1) 0%, rgba(var(--blue), 0) 80%);
							color: white;
						}
					}
				}

				.member {
					display: flex;
					cursor: pointer;
					min-width: 20rem;
					min-height: 24rem;
					background: rgba(var(--white), 1);
					border-radius: 2.5rem;
					overflow: hidden;
					position: relative;
					transition: $button;

					@include breakpoint(sm-down) {
						min-width: 15rem;
						min-height: unset;
					}

					figure {
						display: flex;
						flex-flow: column nowrap;
						justify-content: flex-end;
						align-items: center;
						width: 100%;
						padding: 2.5rem 1rem 0;
						box-sizing: border-box;
						transition: $button;
						overflow: hidden;

						@include breakpoint(sm-down) {
							max-width: 15rem;
							padding: 140% 0 0;
						}

						img {
							height: auto;

							@include breakpoint(sm-down) {
								position: absolute;
							}
						}
					}

					.inner {
						width: 100%;
						padding: 4rem 2rem 2.25rem;
						box-sizing: border-box;
						border-radius: 0 0 2.5rem 2.5rem;
						background: linear-gradient(to top, rgba(var(--white), 1) 20%, rgba(var(--white), 0) 100%);
						color: rgba(var(--dark), 1);
						position: absolute;
						left: 0;
						bottom: 0;
						transition: $button;

						@include breakpoint(sm-down) {
							padding: 0 0 2rem 1.5rem;
						}

						h1 {
							margin: 0 0 0.5rem;
							font-size: 1.625rem;
						}

						span {
							font-weight: 400;
							font-size: 1rem;
						}
					}

					.profile {
						width: 8rem;
						height: 8rem;
						border-radius: 4rem;
					}
				}
			}
		}
	}
}