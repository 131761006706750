.lang-box {
	display: flex;
	flex-flow: row nowrap;
	padding: 1rem;
}

.lang {
	&-selector {
		position: relative;
		cursor: pointer;

		ul {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: stretch;

			padding: 0.125rem 0.0625rem;
			margin: -0.1875rem;
			background: rgba(var(--white), 1);
			border-radius: 0.5rem;
			position: absolute;
			top: 0;

			opacity: 0;
			transform: scale(0);
			transform-origin: center top;
			transition: $button;

			@include breakpoint(sm) {
				margin-left: -1.1875rem;
			}

			&.active {
				opacity: 1;
				transform: scale(1);
			}

			&.grey {
				background: $grey;
			}

			li {
				display: flex;
				flex-flow: row nowrap;
				align-items: flex-start;
				justify-content: flex-start;
				box-sizing: border-box;
				padding: 0.0625rem 0.125rem;

				a {
					@extend .lang-box;
					width: 100%;
					border-radius: 0.375rem;
					color: rgba(var(--dark), 1);
					text-transform: capitalize;

					&:hover {
						background: rgba(var(--accent), 1);
						color: rgba(var(--white), 1);
					}
				}
			}
		}
	}

	&-current {
		@extend .lang-box;
		margin-left: -1rem;

		text-transform: capitalize;
		opacity: 0.64;
		transition: $button;

		&:hover {
			opacity: 1;
		}
	}
}