// @import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
 // @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

$font-heading: 'EB Garamond', 'Nanum Myeongjo', serif;
$font-primary: 'Inter';
// $font-serif: 'IBM Plex Serif';
$font-mono: 'Space Mono';

:root {
    font-size: 16px;
	font-family: $font-primary, sans-serif;
    color: rgba(var(--dark), 1);
}

a {
    color: currentColor;
}

._serif {
    // font-family: $font-serif;
    font-size: 1.375rem;
    opacity: 1;
}

._lspace {
    letter-spacing: -1.5px;
}

._sans {
    font-family: $font-primary;
}

._code {
    font-family: $font-mono;
    letter-spacing: -4%;
}

.ct {
    text-align: center;
}

h1, h2, h3, h4 {
    line-height: 112%;
    font-family: $font-heading;
    font-weight: 400;

    i {
        color: rgba(var(--accent), 1);
    }
}

h1 {
    margin-bottom: 2.5rem;
	font-size: 3.5rem;
    // font-weight: 500;
    line-height: 112%;

    @include breakpoint(sm-down) {
        margin-bottom: 1.5rem;
        font-size: 2.75rem
    }

    &._hero {
        // font-weight: 600;
        font-size: 2.5rem;

        @include breakpoint(sm) {
            font-size: 3rem;
        }
    }
}

h2 {
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: 3rem;

    @include breakpoint(sm-down) {
        margin-bottom: 1rem;
        font-size: 2.25rem
    }
}

h3 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 116%;

    @include breakpoint(sm-down) {
        margin-bottom: 1rem;
        font-size: 1.75rem
    }
}

h4 {
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 116%;

    @include breakpoint(sm-down) {
        margin-bottom: 1rem;
        font-size: 1.25rem
    }
}

._small {
    font-size: 1rem;
}

p {
    margin-bottom: 1rem;
    line-height: 144%;
    font-size: 1.125rem;
    font-family: $font-primary;
    color: rgba(var(--dark), 0.8);

    @include breakpoint(sm-down) {
        font-size: 1.0625rem;
    }

    strong, b {
        font-weight: 500;

        @include breakpoint(sm-down) {
            font-weight: 500;
        }
    }

    &.serif {
        font-size: 1.375rem;
    }

    a {
        &:not(.button) {
            color: rgba(var(--accent), 1);
            text-decoration: underline;
            transition: $t-fast;

            &:hover {
                background: rgba(var(--accent), 1);
                color: rgba(var(--white), 1);
                text-decoration: underline;
            }
        }

        &:hover {
            color: rgba(var(--accent), 1);
        }
    }
}

i {
    font-style: italic;
}

._centered {
    text-align: center;
}

._intro_p,
._intro_p * {
    line-height: 128%;
    font-family: $font-heading;
    font-size: 1.375rem;
    opacity: 1;

    @include breakpoint(sm) {
        font-size: 1.375rem;
    }

    @include breakpoint(md) {
        font-size: 1.625rem;
    }
}

._content,
.content-page {
    a {
        color: rgba(var(--accent), 1);
        text-decoration: underline;
        opacity: 1;
    }

    p {
        margin: 0.5rem 0 0.25rem;

        & + h2,
        & + h3,
        & + h4,
        & + h5 {
            margin-top: 2.5rem
        }
    }

    ul, ol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 1rem 0;
        // margin-bottom: -1rem;
        counter-reset: section;

        li {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0.375rem;
            padding-left: 1.25rem;
            font-size: 1.125rem;
            line-height: 160%;
            // list-style: number;
            list-style: none;
            color: rgba(var(--dark), 0.8);
            position: relative;

            @include breakpoint(sm-down) {
                font-size: 1.0625rem;
            }

            &:before {
                content: '';
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 0.5rem;
                height: 0.5rem;
                margin: 0.625rem 0.75rem 0 0;
                border-radius: 1rem;
                background: rgba(var(--accent), 1);
                position: absolute;
                left: 0;
            }

            &:marker {
                display: none;
            }
        }
    }

    ol li {
        margin-bottom: 0.75rem;
        padding-left: 2.75rem;
        align-items: baseline;

        &:before {
            content: counter(section);
            counter-increment: section;
            width: 2rem;
            height: 2rem;
            margin: 0 1rem 0 0;
            font-size: 1.125rem;
            font-weight: 600;
            color: rgba(var(--white), 1);
        }
    }
    
    h2 {
        margin: 1.5rem 0 1.25rem;
        font-size: 3rem;

        @include breakpoint(sm-down) {
            margin: 0.5rem 0 1rem;
            font-size: 2.25rem
        }
    }

    h3 {
        margin: 1.5rem 0 0.75rem;
        font-size: 1.875rem;

        @include breakpoint(sm-down) {
            margin: 1rem 0 0.5rem;
            font-size: 1.625rem
        }
    }

    h4 {
        margin: 1.25rem 0 0.75rem;
        font-size: 1.5rem;
        
        @include breakpoint(sm-down) {
            margin: 0.5rem 0 1rem;
            font-size: 1.25rem
        }
    }
}