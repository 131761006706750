.gallery {
	&-wrapper {
		margin: 1rem 0;

		@include breakpoint(sm) {
			margin: 2.5rem 0;
		}

		.swiper {
			overflow: visible;

			&-slide {
				width: auto !important;
				max-width: 100%;

				img {
					border-radius: 0.5rem;
				}
			}
		}
	}
}

.swiper {
    &-pagination {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 0.375rem 0 0;

        &-lock {
            display: none;
        }

        &-bullet {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0.75rem;
            margin: 0.25rem;
            background: rgba(var(--dark), 0.25);
            transform: scale(0.8);
            transition: $button;

            &-active {
                background: rgba(var(--accent), 1);
                transform: scale(1);
            }
        }
    }
}