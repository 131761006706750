// Responsive breakpoints
@mixin breakpoint($size) {
    @if $size == xl {@media (min-width: 100rem) { @content }}
    @else if $size == lg {@media (min-width: 78rem) { @content }}
    @else if $size == md {@media (min-width: 64rem) { @content }}
    @else if $size == sm {@media (min-width: 44rem)  { @content }}
    @else if $size == xl-down {@media (max-width: 100rem) { @content }}
    @else if $size == lg-down {@media (max-width: 78rem) { @content }}
    @else if $size == md-down {@media (max-width: 64rem) { @content }}
    @else if $size == sm-down {@media (max-width: 44rem)  { @content }}
}
