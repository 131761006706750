.oqin-logo {
    width: 100%;
    position: absolute;
    left: 0;
    pointer-events: none;

    a {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        pointer-events: all;

        svg {
            // margin: 0 0.5rem;

            @include breakpoint(sm-down) {
                height: 3rem;
                width: auto;
            }
        }

        .method-indicator {
            display: flex;
            box-sizing: border-box;
            border-radius: 0.25rem;
            opacity: 0;
            width: 0;
            margin-left: 0.5rem;
            overflow: hidden;
            line-height: 2rem;
            font-size: 2rem;
            font-weight: 700;
            background: currentColor;
            cursor: pointer;

            ._code {
                padding: 0.125rem 0.375rem 0.25rem;
                display: inline;
                white-space: nowrap;
                color: rgba(var(--white), 1);
            }
        }
    }
}

.canada {
    width: 2rem;
    margin: 0 0 0 0.5rem;
    position: relative;
    color: rgba(var(--accent), 1);

    &.accent {
        color: rgba(var(--white), 1) !important;
    }

    &.light,
    &.dark {
        color: rgba(var(--accent), 1) !important;
    }

    &.loaded {
        svg {
            transform: scale(1);
        }
    }

    svg {
        width: auto;
        height: 3.5rem;
        color: inherit;
        transform: scale(0);
        transition: $t-fast;

        @include breakpoint(sm-down) {
            height: 2.5rem;
        }
    }
}