.button {
    all: unset;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    box-sizing: border-box;
    border-radius: 2rem;
    cursor: pointer;
    color: currentColor;
    position: relative;
    transition: $button;
    text-decoration: none !important;

    @include breakpoint(sm-down) {
        height: 3.25rem;
    }

    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: $button;
    }

    &:before {
        z-index: 1;
    }

    &-wrap {
        @include breakpoint(sm-down) {
            width: 100%;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            .button {
                margin: 0 0 0.5rem !important
            }
        }
    }

    & ~ .button {
        margin-left: 2.5rem;

        @include breakpoint(sm-down) {
            margin-left: 1.5rem;
        }
    }

    &.nt {
        transition: none;

        i,
        span,
        &:before,
        &:after {
            transition: none;
        }
    }

    span {
        white-space: nowrap;
        line-height: 144%;
        font-size: 1.25rem;
        color: inherit;
        -webkit-text-fill-color: inherit;
        position: relative;
        z-index: 4;
        transition: $button;

        @include breakpoint(sm-down) {
            font-size: 1.125rem;
        }
    }

    i { // icon
        transition: $button;
        position: relative;
        color: inherit;
        z-index: 3;

        & ~ span {
            margin-left: 1.0625rem;
        }
    }

    &.primary {
        padding: 0 2rem;
        border-radius: 3rem;

        &:before {
            border-radius: 3rem;
            background: currentColor;
            opacity: 0;
            transition: none;
        }

        &:after {
            border-radius: 3rem;
            box-shadow: inset 0 0 0 0.0625rem currentColor;
            opacity: 0.2;
        }

        &:hover,
        &._active {
            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 0;
            }

            span, i {
                color: rgba(var(--white), 1);
                transition: none;
            }
        }

        span, i {
            color: currentColor;
        }
    }

    &.secondary {
        &:after {
            height: 0.125rem;
            background: currentColor;
            top: auto;
            bottom: 0;
            opacity: 0.2;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        span, i {
            color: currentColor
        }
    }

    &-share {
        padding: 1rem;
        color: rgba(var(--dark), 1);

        svg {
            width: 2rem;
            height: auto;
        }
    }
}