.popup {
	&-fixture {
		padding: 1.5rem 2rem 1.375rem;
		background: rgba(var(--white), 1);
		box-shadow: 0 0.5rem 1rem rgba(var(--dark), 0.16);
		border-radius: 1rem;
		opacity: 0;
		color: rgba(var(--accent), 1);
		pointer-events: none;
		transform-origin: center;
		box-sizing: border-box;
		position: absolute;
		// top: 0;
		// left: 0;
		z-index: 1;

		&.active {
			pointer-events: all;
		}

		// &:hover {
		// 	background: rgba(var(--accent), 1);
		// 	color: rgba(var(--white), 1);

		// 	* {
		// 		color: rgba(var(--white), 1);
		// 	}
		// }
	}

	.title {
		margin-bottom: 0.5rem;
		font-weight: 600;

		i {
			margin-right: 0.75rem;
		}
	}

	p {
		margin-bottom: 0.25rem;
		opacity: 1;
		color: rgba(var(--dark), 1);
	}
}