.timeline {
	&-fixture {
		// width: 100%;

		// background: rgba(var(--accent), 0.2);
		width: 3002%;
		display: flex;
		flex-flow: column nowrap;
		-ms-flex-item-align: center;
		align-self: center;
		height: 100vh !important;
	}

	&-wrapper {
		.pin-spacer {
			.timeline {
				&-fixture {
					// height: 100vh;
					flex-flow: row nowrap !important
				}
			}
		}
	}

	&-item {
		display: flex;
		flex-shrink: 0;
		max-width: 100vw;
		height: 100%;
		min-height: 20rem;
		padding: 0 1rem;
		box-sizing: border-box;
		overflow: hidden;

		@include breakpoint (sm) {
			width: 30rem;
		}

		// &:after {
		// 	content: '';
		// 	width: 100%;

		// 	border-bottom: 2px dashed rgba(var(--accent), 0.24);
		// 	position: absolute;
		// 	left: 5rem;
		// 	top: 2.5rem;
		// }

		// &:before {
		// 	content: '';
		// 	width: 100%;

		// 	border-bottom: 2px dashed rgba(var(--accent), 0.24);
		// 	position: absolute;
		// 	left: 0;
		// 	bottom: 2.5rem;
		// }

		&:last-child {
			&:after {
				display: none;
			}
		}

		.inner {
			width: 100%;
			height: 100%;
			padding: 0;
			box-sizing: border-box;
			border-radius: 2.5rem;
			position: relative;
			background: rgba(var(--accent), 0.08);

			&-content {
				margin: 1.5rem 4rem 2.5rem;
				position: relative;

				.title {
					// height: 5rem;
					margin-bottom: 1rem;

					h2, h3, h4, h5 {
						margin: 0;
					}
				}

				.content {
					p {

					}
				}
			}

			.label {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				width: 5rem;
				height: 5rem;
				margin: 2rem;
				border-radius: 100%;
				font-size: 2rem;
				font-weight: 400;
				color: rgba(var(--dark), 1);
				position: relative;

				span {
					&:last-child {
						position: absolute;
					}
				}
			}
		}
	}

	&-step {
		width: 20rem;
		height: 20rem;
		border-radius: 100%;
		margin-bottom: 2rem;
		background: rgba(var(--accent), 1);
	}
}