.usps {
	article {
		padding-right: 2.5rem;
		box-sizing: border-box;

		@include breakpoint(sm-down) {
			margin-bottom: 2rem;
		}

		figure {
			margin-right: 2.5rem;

			@include breakpoint(sm-down) {
				margin: 0 1.5rem 0 0.5rem;
			}
		}

		h1 {
			@extend h3;
			margin-top: 0.375rem;
		}
	}
}