.hero {
	p {
		margin-bottom: 3rem;
		padding-right: 5rem;
		box-sizing: border-box;
		font-size: 1.375rem;

		@include breakpoint (sm-down) {
			margin-bottom: 1.5rem;
		}
	}
}