.method {
	&-wrapper {
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			background: rgba(var(--accent), 0.06);
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&-slider {
		display: flex;
		flex-flow: row nowrap;
		align-content: center;
		justify-content: space-between;
		position: relative;
		z-index: 10;

		@include breakpoint(md-down) {
			justify-content: center;
		}

		&.content {
			@include breakpoint(sm-down) {
				padding-top: 2rem;
			}
		}

		figure {
			display: flex;
			flex-shrink: 0;
			// min-width: 10rem;
			min-height: 4rem;
			padding-right: 2rem;
			position: relative;
			z-index: 99;

			@include breakpoint(sm-down) {
				padding-right: 1rem;
				max-height: 5rem;
			}

			.logo {
				display: inline-flex;
				width: auto;
				height: auto;
				min-width: 10rem;
				min-height: 4rem;
				position: relative;
				z-index: 999;
				flex-shrink: 0;

				@include breakpoint(sm) {
					flex-shrink: 1;
					// max-width: 30vw;
					// width: 100%;
				}

				@include breakpoint(md) {
					max-width: 100%;
					width: 100%;
				}

				&-dot {
					fill: rgba(var(--accent), 1);
					transition: $button;
					transition-property: color;
				}
			}
		}

		.swiper {
			display: flex;
			width: auto;
			margin: 0;

			&.thumbs {
				width: 6.5rem;
				height: auto;
				overflow: visible;

				@include breakpoint(sm) {
					width: auto;
					height: 6rem;
				}

				@include breakpoint(sm) {
					height: 10rem;
				}

				.swiper-slide {
					filter: grayscale(100%);
					opacity: 0.06;

					@include breakpoint(md-down) {
						opacity: 0;
					}

					&.swiper-slide-prev,
					&.swiper-slide-next {
						filter: grayscale(100%);
						opacity: 0.12;

						@include breakpoint(md-down) {
							opacity: 0;
						}


						&:hover {
							opacity: 0.24;

							@include breakpoint(md-down) {
								opacity: 0;
							}
						}
					}

					&.swiper-slide-active {
						filter: grayscale(0%);
						opacity: 1;
					}
				}

				.method-indicator {
					display: flex;
					padding: 0.25rem 0.5rem;
					box-sizing: border-box;
					border-radius: 0.25rem;
					line-height: 3rem;
					font-size: 3rem;
					font-weight: 700;
					background: currentColor;
					cursor: pointer;

					@include breakpoint(sm) {
						padding: 1.25rem 1rem;
						border-radius: 0.75rem;
						line-height: 4rem;
						font-size: 4rem;
					}

					@include breakpoint(md) {
						padding: 1.25rem 1rem;
						border-radius: 0.75rem;
						line-height: 6rem;
						font-size: 6rem;
					}

					._code {
						display: inline;
						color: rgba(var(--white), 1);
					}
				}

				&.swiper {
					&-horizontal {
						.swiper-slide {
							width: auto;
						}
					}
				}
			}

			&.content {
				width: 100%;

				@include breakpoint(sm) {
					margin-top: 2.5rem;
				}

				@include breakpoint(md) {
					margin: 0;
				}

				.swiper-slide:not(.swiper-slide-active) {
					filter: grayscale(100%);
					opacity: 0
				}

				.swiper-wrapper {
					pointer-events: none;
				}

				.swiper-slide {
					transition-duration: 640ms;

					@include breakpoint(sm) {
						padding: 0 2.5rem; 
					}

					a.button {
						pointer-events: all;

						// @include breakpoint(sm) {
							margin-top: 1.5rem;
						// }
					}
				}
			}

			&-slide {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				height: unset;
				max-width: 100%;
				transition: $button;
				transition-property: opacity, filter;
			}
		}
	}
}