.process {
	article {
		margin-bottom: 1.5rem;
		.head {
			width: 100%;
			margin-bottom: 1rem;

			color: rgba(var(--accent), 1);

			h1 {
				margin: 0;
				padding: 0 0.25rem
			}

			svg {
				margin: 0 0.25rem;

				@include breakpoint (sm-down) {
					display: none;
				}
			}
		}
	}	
}