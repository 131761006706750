// var colors
:root,
.light {
	--accent:  242,  25,  62;

	--red:     208,   0,   3;
	--orange:  235,  95,   0;
	--green:    36, 173,  12;

	--blue:     24,  56, 224;
	--yellow:  255, 168,   0;
	--purple:  152, 110, 226;
	--gold:    174, 159,  52;

	--white:   255, 255, 255;
	--light:   244, 244, 244;
	--medium:  144, 144, 144;
	--dark:     16,  25,  38;

	--pro:     0,195,90;
	--ris:     112,24,224;
	--val:     255,92,0;

	// specials colors
	--sign-shadow: var(--yellow);

	.dark {
		--white:    16,  25,  38;
		--light:   144, 144, 144;
		--medium:  244, 244, 244;
		--dark:    255, 255, 255;
	}
}

// Static colors
$dark: #000000;
$white: #ffffff;
$grey: #E7E8E9;

// Transitions
$t-button: 200ms ease-out;
$t-fast: 120ms ease;
$t-menu: 320ms cubic-bezier(.42,.04,.39,1.16);

// Border-radius
$br-button: 0.25rem;
$br-default: 0.25rem;

// Color palette
.accent  { color: rgba(var(--accent), 1) !important }
.orange  { color: rgba(var(--orange), 1) !important }
.purple  { color: rgba(var(--purple), 1) !important }
.gold    { color: rgba(var(  --gold), 1) !important }
.green   { color: rgba(var( --green), 1) !important }
.white   { color: rgba(var( --white), 1) !important }
.dark    { color: rgba(var(  --dark), 1) !important }
.grey    { color: rgba(var(--medium), 1) !important }
.blue    { color: rgba(var(  --blue), 1) !important }
.red     { color: rgba(var(   --red), 1) !important }

.pro     { color: rgba(var(   --pro), 1) !important }
.ris     { color: rgba(var(   --ris), 1) !important }
.val     { color: rgba(var(   --val), 1) !important }

$button: 200ms cubic-bezier(0.215, 0.610, 0.355, 1.000);

.method {
	&-ris { --accent: var(--ris); }
	&-val { --accent: var(--val); }
	&-pro { --accent: var(--pro); }
}