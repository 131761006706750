.list {
	padding-top: 0;
	padding-bottom: 0;

	&.small {

		ul {
			li {
				padding: 0.25rem 0;
			}
		}

		.bullet {
			margin-right: 1.5rem;
			box-sizing: border-box;
			box-shadow: none;
			border-radius: 2rem;
			background: rgba(var(--accent), 0.16);
			color: rgba(var(--accent), 1);
		}
	}

	ul {
		&.double {
			@include breakpoint(sm) {
				column-count: 2;
				column-gap: 2.5rem;
			}
		}

		li {
			box-sizing: border-box;
			padding: 1rem 2.5rem 1rem 0.5rem;
			break-inside: avoid-column;

			p {
				margin: 0;
			}

			.bullet {
				flex-shrink: 0;

				width: 2rem;
				height: 2rem;
				margin-right: 1rem;
				padding: 9px 0 0 7px;

				box-sizing: border-box;
				box-shadow: 0 0.25rem 0.5rem rgba(var(--accent), 0.16);
				border-radius: 2rem;
				background: rgba(var(--accent), 1);

				color: $white;

				@include breakpoint(sm) {
					margin-right: 2.5rem;
				}
			}
		}
	}
}